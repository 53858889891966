import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router'
import Box from '../../RadioUI/Box'
import FrontPage from './FrontPage'
import FrontPageLoading from './FrontPageLoading'
import { useFrontpageData } from './hooks'
import SectionPage from './SectionPage'
import { MyContentProvider, useFetchMyContent } from '../../components/MyContent/MyContentContext'
import { SeriesSubmissionsProvider } from '../../components/SeriesSubmissions/SeriesSubmissionsContext'

const FrontPageContainer = props => {
  const { isLoading } = useFrontpageData()

  const fetchMyContent = useFetchMyContent()
  useEffect(() => {
    fetchMyContent()
  }, [fetchMyContent])

  return (
    <Switch>
      <Route path="/seksjon/:sectionId" exact>
        {isLoading ? <Box minH="100vh" /> : <SectionPage />}
      </Route>
      <Route path="/" exact>
        {isLoading ? <FrontPageLoading /> : <FrontPage />}
      </Route>
    </Switch>
  )
}

FrontPageContainer.propTypes = {}

const FrontPageContainerWithProviders = props => {
  return (
    <SeriesSubmissionsProvider>
      <MyContentProvider>
        <FrontPageContainer />
      </MyContentProvider>
    </SeriesSubmissionsProvider>
  )
}

export default FrontPageContainerWithProviders
